@import url(https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{font-family:"Monaco";font-style:normal;font-weight:normal;src:local("Monaco"),url("/Monaco.woff") format("woff")}@font-face{font-family:"cour";font-style:normal;font-weight:normal;src:local("cour"),url("/cour.ttf") format("ttf")}.divider{width:80%;padding-bottom:10px;margin:0 auto;margin-top:30px;font-size:12px;border-bottom:1px solid #fff}.videos{width:80%;margin:0 auto;margin-top:20px}.videos .videos-text-wrap{display:flex;justify-content:space-between;margin-bottom:5px;font-size:14px}.videos .videos-content{width:100%;margin-bottom:10px}.videos .video-content-image{width:100%;height:168px;background-size:100%;margin-bottom:10px}.profile{width:80%;margin:0 auto;margin-top:20px}.profile .profile-text-wrap{display:flex;justify-content:space-between;margin-bottom:5px;font-size:14px}.profile .profile-content{width:100%;height:168px;background-size:100%;margin-bottom:10px}.contactus{margin-top:15px;width:80%;margin:0 auto;margin-top:20px;margin-bottom:130px}.contactus .contactus-text-wrap .contactus-address{font-size:14px}.contactus .contactus-text-wrap .contactus-email{font-size:14px;margin-top:10px}body{background-color:#02030f}*{font-family:"cour", "Monaco";padding:0;margin:0;color:white;font-size:14px}.home{background-color:#02030f;width:100%;margin:0 auto;max-width:375px}.home .logo{width:80%;margin:0 auto;margin-top:50px;margin-bottom:50px}.home .logo .logo-title{width:100%}.home .logo .logo-since{width:100%;font-size:11px;text-align:left;display:block}.description{opacity:0;height:0;overflow:hidden}.description-hover{transition:opacity 1s ease-out;opacity:1;margin-top:30px;height:auto;font-size:14px;white-space:pre-line}.sign{width:100%;text-align:right}.sign .sign-img{width:150px}

