// Black Han Sans ,sans-serif
@import url("https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap");

@font-face {
    font-family: "Monaco";
    font-style: normal;
    font-weight: normal;
    src: local("Monaco"), url("/Monaco.woff") format("woff");
}

@font-face {
    font-family: "cour";
    font-style: normal;
    font-weight: normal;
    src: local("cour"), url("/cour.ttf") format("ttf");
}
